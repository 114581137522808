<template>
  <v-form :id="id" data-cy-form @submit="onSubmit">
    <slot />
  </v-form>
</template>

<script lang="ts" setup>
import { useForm } from "vee-validate";

const properties = defineProps<{
  id?: string;
  scrollToErrorDelay?: number;
}>();

const emit = defineEmits<{
  submit: [payload: Record<string, unknown>];
  "invalid-submit": [];
}>();

const { handleSubmit } = useForm();

const onSubmit = handleSubmit(
  (form) => emit("submit", form),
  async (form) => {
    emit("invalid-submit");

    const firstInvalidFieldName = Object.entries(form.errors)[0]?.[0];

    if (properties.scrollToErrorDelay !== undefined) {
      await timer(properties.scrollToErrorDelay);
    }

    const element = document.querySelector<HTMLElement>(
      `[data-field-name="${firstInvalidFieldName}"]`,
    );

    if (!element) return;

    const position = element.getBoundingClientRect();

    scrollTo({
      top: position.top + (document.scrollingElement?.scrollTop ?? 0) - 100,
      behavior: "smooth",
    });
  },
);
</script>
